<template>
  <div class="flex-grow-1">
    <admin-title title="General Settings"></admin-title>

    <v-card class="custom-shadow" max-width="1000" outlined>
      <v-form ref="settingsForm">
        <v-simple-table class="setting-table">
          <template #default>
            <thead>
              <tr>
                <th width="33%">Name</th>
                <th width="34%">Description</th>
                <th width="33%">Value</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr>
                <td>Page Builder Marketing Banner</td>
                <td class="caption">
                  The number of panels the subscriber needs to add in their project before they see the promotional banner.
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.pageBanner"
                    :rules="[rules.required]"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr> -->
              <tr>
                <td>PDF Downloads</td>
                <td class="caption">
                  The number of PDF Downloads a user needs to make before triggering a tag to be added in Active Campaign.
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.pdfDownloads"
                    :rules="[rules.required]"
                    type="number"
                    hide-details
                    label="PDF Downloads"
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <!-- <tr>
                <td>Max Projects Per User</td>
                <td class="caption">
                  The number of projects a subscriber is allowed to create in Vivacity App.
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.maxProjects"
                    :rules="[rules.required]"
                    type="number"
                    hide-details
                    label="Max Projects Per User"
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr> -->
              <tr>
                <td>Good feedback webhook URL</td>
                <td class="caption">
                  None
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.goodFeedback"
                    :rules="[rules.required]"
                    hide-details
                    outlined
                    label="Good feedback webhook URL"
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>Bad feedback webhook URL</td>
                <td class="caption">
                  None
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.badFeedback"
                    :rules="[rules.required]"
                    hide-details
                    label="Bad feedback webhook URL"
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="font-weight-bold">
                  Active Campaign Settings
                </td>
              </tr>
              <tr>
                <td colspan="2">AC API URL</td>
                <td>
                  <v-text-field
                    v-model="settings.acApiUrl"
                    :rules="[rules.required, rules.url]"
                    hide-details
                    label="AC API URL"
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td colspan="2">AC API Key</td>
                <td>
                  <v-text-field
                    v-model="settings.acApiKey"
                    :rules="[rules.required]"
                    type="password"
                    hide-details
                    label="AC API Key"
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>PDF Download AC Tag</td>
                <td class="caption">
                  The tag that will be added in Active Campaign once the user meets the number of PDF Downloads required.
                </td>
                <td>
                  <v-text-field
                    v-model="settings.pdfAcTag"
                    :rules="[rules.required]"
                    hide-details
                    label="PDF Download AC Tag"
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>New User AC Tag</td>
                <td class="caption">
                  The tag that is added along with the contact details of every new Vivacity App subscriber.
                </td>
                <td>
                  <v-text-field
                    v-model="settings.newUserTag"
                    :rules="[rules.required]"
                    hide-details
                    label="New User AC Tag"
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="font-weight-bold">
                  Request Settings
                </td>
              </tr>
              <tr>
                <td>Message Response Time Limit</td>
                <td class="caption">
                  ---
                </td>
                <td>
                  <v-text-field
                    :suffix="pluralize('hour', settings.requestResponseTime || 1)"
                    v-model.number="settings.requestResponseTime"
                    :rules="[rules.required]"
                    hide-details
                    label="Message Response Time Limit"
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="font-weight-bold">
                  Order Settings
                </td>
              </tr>
              <tr>
                <td>Message Response Time Limit</td>
                <td class="caption">
                  ---
                </td>
                <td>
                  <v-text-field
                    :suffix="pluralize('hour', settings.orderResponseTime || 1)"
                    v-model.number="settings.orderResponseTime"
                    :rules="[rules.required]"
                    hide-details
                    label="Message Response Time Limit"
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions class="pa-3">
        <v-btn
          @click="validateForm()"
          :loading="status.saving"
          class="purple gradient"
        >Save Settings</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import rules from '@/rules'
import pluralize from 'pluralize'
import { mapState, mapActions } from 'vuex'

export default {
  metaInfo: {
    title: 'General Settings'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      pluralize,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      settings: (state) => state.generalSettings.settings,
      status: (state) => state.generalSettings.status,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('generalSettings', [
      'saveSettings',
      'getSettings'
    ]),

    validateForm() {
      if (this.$refs.settingsForm.validate()) {
        this.saveSettings()
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getSettings()
  }
}
</script>

<style lang="scss">

.setting-table {
  tr {
    td {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}

</style>